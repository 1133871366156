import React from 'react';
import './Form.css';

const FeedbackFormBack = () => {

  return  (
    <div class="main_container1">

    <div class="container1">
       <div class="header1">
          <img src="formimages/canara_icon.png" class="logo_icon" />
          <span>
          <img src="formimages/canara_logo.png" class="logo" />
          <h4>Together We can</h4>
          </span>
          
       </div>
       
       <div class="content_style1">
          <img src="formimages/banner.png" /> 
          </div>
          </div>
          </div>
  );
};

export default FeedbackFormBack;
