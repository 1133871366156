import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div style={styles.container}>
      <p style={styles.heading}>404</p>
      <p style={styles.subheading}>Oops! Page Not Found</p>
      <p style={styles.para}>We're sorry, but the page you're looking for doesn't exist or has been moved.</p>
      {/*<button style={styles.button} onClick={handleGoHome}>
        Go Back Home
      </button>
      <img
        src="https://via.placeholder.com/400x250"
        alt="Not Found"
        style={styles.image}
      />*/}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
  },
  heading:{
    fontSize: "8rem",
    color:" #ff6b6b",
    marginBottom: "10px",
    fontWeight: "bold", 
  },
  subheading: {
    fontWeight: "bold", 
    fontSize: '24px',
    color: 'black',
  },
  para:{
    fontSize: '20px',
    color:'grey',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '20px 0',
  },
  image: {
    width: '400px',
    height: '250px',
    marginTop: '20px',
  },
};

export default NotFound;
