import { BrowserRouter,Routes, Route, createBrowserRouter, RouterProvider } from 'react-router-dom';
import Mobile from './components/Mobile';
import Feedback from './components/Feedback';
import Otp from './components/Otp';
import QR from './components/QR';
import ThankYou from './components/ThankYou';
import EmployeeCode from './components/employeeCode';
import SalesforceLogin from './components/salesForceLogin';
import FeedbackServiceFlow from './components/FeedbackServiceFlow';
import { Provider } from 'react-redux';
import FeedbackMessage from './components/FeedbackMessage';
import store from './store';
import { ToastContainer} from 'react-toastify';
import FeedbackMessageService from './components/FeedbackMessageService';
import { useEffect,useState } from 'react';
import PrivateRoute from './utils/PrivateRoute';
import $ from "jquery";
import FeedbackFormFront from './components/FeedbackFormFront';
import FeedbackFormBack from './components/FeedbackFormBack';
import NotFound from './components/NotFound';

function App() {
  const [isIframe, setIsIframe] = useState(false);
  useEffect(() => {
    // Check if the current window is not the top window
    if (window.self !== window.top) {
      setIsIframe(true); // Set to true if the app is inside an iframe
    }
  }, []);

  if (isIframe) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontSize: '24px',
        color: '#333',
        backgroundColor: '#f5f5f5' // Optional: Light gray background
      }}>
        <p>Refused to connect. This page cannot be displayed in an iframe.</p>
      </div>
    );
  }

  const router = createBrowserRouter([
    {
      path: '*', 
      element: <NotFound />,
    },
    {
      path: "/cankiosk",
      element: <FeedbackServiceFlow />,
    },
    // {
    //   path: "/phone-number",
    //   element: <Mobile/>,
    // },
    // {
    //   path: "/QR",
    //   element: <QR/>,
    // },
    {
      path: "/employee-code",
      element: <EmployeeCode/>,
    },
    {
      path: "/employee-feedback",
      element: <SalesforceLogin />,
    },
    {
      path: "/feedback-response-service",
      element: <FeedbackMessageService />,
    },  
    {
      path: "/feedback-Form-Front",
      element: <FeedbackFormFront />,
    },
    {
      path: "/feedback-Form-Back",
      element: <FeedbackFormBack />,
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: "/qr",
          element: <QR/>,
        },
        {
          path: "/phone-number",
          element: <Mobile/>,
        },
        {
          path: "/feedback",
          element: <Feedback/>
        },
        {
          path: "/otp-verification",
          element: <Otp/>
        },
        {
          path: "/thank-you",
          element: <ThankYou/>
        },
        {
          path: "/feedback-response",
          element: <FeedbackMessage />,
        },
        // {
        //   path: "/employee-code",
        //   element: <EmployeeCode/>,
        // },
      ]
    } 
  ])

  //    $(document).bind("contextmenu",function(e) {
  //     e.preventDefault();
  // });


  return (
    <Provider store={store}>
      <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                limit={1}
                />

               <ToastContainer />
               <RouterProvider router={router} >
      </RouterProvider>
   
  </Provider>
  );
}

export default App;
