/** @format */

import React, { useEffect } from "react";
import {
  Navigate,
  Outlet
} from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const params = new URLSearchParams(window.location.search);
    const data = params.get('data') || null
    const empCode = params.get('emp_code') || localStorage.getItem('employeeCode') || localStorage.getItem('emp_code');
    const empName = params.get('emp_name') || localStorage.getItem('employeeName');
    const empDpCode = params.get("dp_code") || localStorage.getItem("employeeDpCode") || localStorage.getItem("dp_code");
    const empBranchName = params.get('branch_name') || localStorage.getItem('employeeBranchName');
    const unique_id = params.get('id');

    console.log(location.pathname, "pathhhh", empDpCode, "empDpCode", empCode)
   
  if (
    (location.pathname === "/QR" && empDpCode && empCode) || 
    (location.pathname === "/qr" && empDpCode && empCode) ||
    (location.pathname === "/feedback" && unique_id) ||
    (location.pathname === "/phone-number" && empDpCode) || 
    localStorage.getItem("phone_number") ||  (location.pathname === "/phone-number" && data)
  ) {
    return <Outlet />;
  }
  else if(location.pathname === "/qr" && (!empDpCode || !empCode )){   
    return <Navigate to ="/employee-feedback" />
  }
     else {      
    return <Navigate to="/" />;
  }
      
  
    
};

export default PrivateRoute;
