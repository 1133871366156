const odiya = {
    feedbackServiceFlow: {
        line1: "ସାଧାରଣ ପ୍ରତିକ୍ରିୟା",
        line2: "ସେବା ସ୍ତର ପ୍ରତିକ୍ରିୟା"
    },
    mobile: {
        line1: "ଆପଣଙ୍କର ମୋବାଇଲ୍ ନମ୍ବର ଲେଖନ୍ତୁ",
        line2: "ଆମେ ଆପଣକୁ ଏକ ଯାଚନା କୋଡ୍ ପଠାଇବା",
        line3: "ମୋବାଇଲ୍ ନମ୍ବର ଲେଖନ୍ତୁ",
        line4: "ଚାଲିଗଲେ",
        error: {
            error: "କିଛି ତ୍ରୁଟି ଘଟିଛି। ଦୟାକରି ପଛରୁ ଆଉ ଚେଷ୍ଟା କରନ୍ତୁ",
            otp: "OTP ପଠାଇବାରେ ବିଫଳ। ଦୟାକରି ପଛରୁ ଆଉ ଚେଷ୍ଟା କରନ୍ତୁ"
        }
    },
    otp: {
        line1: "ୟାଚନା କୋଡ୍",
        line2: "ଆମେ ଆପଣଙ୍କର ମୋବାଇଲ୍ ନମ୍ବରକୁ ଯାଚନା କୋଡ୍ ପଠାଇଛୁ",
        line3: "OTP ମିଳିଲାନି?",
        line4: "ଚାଲିଗଲେ",
        line5: "ପ୍ରତିକ୍ରିୟା ସେବା ପ୍ରକାର ଚୟନ କରନ୍ତୁ",
        line6: "ନଗଦ ଜମା",
        line7: "ଫଣ୍ଡ ଟ୍ରାନ୍ସଫର୍",
        line8: "ଖାତା ଖୋଲିବା",
        line9: "SB ଖାତା ସଂପର୍କିତ ସେବା",
        line10: "ବର୍ତ୍ତମାନ ଖାତା",
        line11: "ବାତିଲ କରନ୍ତୁ",
        line12: "ପରବର୍ତ୍ତୀ",
        line13: "OTP ପୁନର୍ବାର ପଠାନ୍ତୁ",
        line14: "OTP ସଫଳତାର ସହ ପଠାଇଯାଇଛି",
        noTransactionTypes: "ଆପଣ କୌଣସି ସେବା ବ୍ୟବହାର କରି ପ୍ରତିକ୍ରିୟା ଦେଇପାରିବେନି",
        error: {
            error: "OTP ପଠାଇବା ସମୟରେ ତ୍ରୁଟି ଘଟିଛି। ଦୟାକରି ପଛରୁ ଆଉ ଚେଷ୍ଟା କରନ୍ତୁ",
            otp: "ଅବୈଧ OTP"
        }
    },
    feedback: {
        line1: "ଆଜି ଆମ ସହିତ ଆପଣଙ୍କର ଅଭିଜ୍ଞତା ମୂଲ୍ୟାଙ୍କନ କରନ୍ତୁ",
        verypoor: "ଖୁବ ଖରାପ",
        belowAverage: "ସାଧାରଣ ତଳେ",
        average: "ସାଧାରଣ",
        good: "ଭଲ",
        veryGood: "ଖୁବ ଭଲ",
        excellent: "অତି উত্তম",
        outstanding: "ଆସାଧାରଣ",
        poorFeedbackHeader: "ଆମେ ଅସନ୍ତୁଷ୍ଟ, ଦୟାକରି ଆପଣଙ୍କର ଅସନ୍ତୁଷ୍ଟି କାରଣ ଏହାର ଖାତିରେ ଉତ୍ତର ଦିଅନ୍ତୁ:",
        badService: "ଖରାପ ସେବା",
        notGoodSupport: "ଭଲ ସମର୍ଥନ ନାହିଁ",
        others: "ଅନ୍ୟ",
        textareaPlaceholder: "ଆମେ ଆପଣଙ୍କର ଅସୁବିଧା ପାଇଁ ଦୁଃଖିତ",
        captchaPlaceholder: "କ୍ୟାପଚା ଲେଖନ୍ତୁ",
        reload: "କ୍ୟାପଚା ରିଲୋଡ୍ କରନ୍ତୁ",
        submit: "ଦାଖଲ କରନ୍ତୁ",
        work: "କାମ ସମ୍ପୂର୍ଣ୍ଣ ହୋଇନି",
        inadequate: "ପ୍ରୋଡକ୍ଟ / ସେବା ଉପରେ କର୍ମୀଙ୍କର ପର୍ୟାପ୍ତ ଜ୍ଞାନ ନାହିଁ",
        guidance: "କାମ କରିବାକୁ ଶୁଭାବନ୍ତୀ ଦିଆଯାଇନି",
        staff: "କର୍ମୀଙ୍କର ବ୍ୟବହାର",
        products: "ପ୍ରୋଡକ୍ଟ/ପ୍ରକ୍ରିୟା ଗ୍ରାହକଙ୍କର ଆବଶ୍ୟକତା ପୂରଣ କରିପାରିବେନି",
        other: "ଅନ୍ୟ କିଛି",
        general1: "କାମ ସାଧାରଣ ଭାବେ ସମ୍ପୂର୍ଣ୍ଣ ହୋଇନି",
        general2: "ପ୍ରୋଡକ୍ଟ/ସେବା ଉପରେ କର୍ମୀଙ୍କର ପର୍ୟାପ୍ତ ଧାରଣା ନାହିଁ",
        general3: "କାମ କରିବାକୁ ଶୁଭାବନ୍ତୀ ଦିଆଯାଇନି",
        general4: "କର୍ମୀଙ୍କର ବ୍ୟବହାର",
        general5: "ପ୍ରୋଡକ୍ଟ/ପ୍ରକ୍ରିୟା ଗ୍ରାହକଙ୍କର ଆବଶ୍ୟକତା ପୂରଣ କରିପାରିବେନି",
        general6: "ଅନ୍ୟ",
        error: {
            selectRating: "ଦୟାକରି ରେଟିଂ ଚୟନ କରନ୍ତୁ",
            incorrectCaptcha: "କ୍ୟାପଚା ତ୍ରୁଟି",
            submitFeedback: "ଆପଣ ଯାଚିତ କରାଯିବା ସୂଚନା ସହିତ ପ୍ରତିକ୍ରିୟା ପ୍ରଦାନ କରିପାରିବେନି",
            twice: "ଆପଣ ଦ୍ବିତୀୟ ଥର ପ୍ରତିକ୍ରିୟା ପ୍ରଦାନ କରିପାରିବେନି",
            transaction: "ଆପଣ କୌଣସି ଟ୍ରାଞ୍ସଫର୍ କରି ପ୍ରତିକ୍ରିୟା ଦେଖାଇପାରିବେନି"
        }
    },
    thankYou: {
        line1: "ପ୍ରତିକ୍ରିୟା ଦେଇଥିବାକୁ ଧନ୍ୟବାଦ,",
        line2: "ଆମେ ଆପଣଙ୍କର ମୂଲ୍ୟାଙ୍କନ କରୁଛୁ"
    },
    feedbackMessage: {
        line1: "ଆମେ ଆପଣଙ୍କର ପ୍ରତିକ୍ରିୟା ଗ୍ରହଣ କରିଛୁ",
        line2: "ଆପଣ ଦ୍ବିତୀୟ ବେଳେ ପ୍ରତିକ୍ରିୟା ପ୍ରଦାନ କରିପାରିବେନି"
    },
    employee: {
        line1: "କର୍ମଚାରୀ କୋଡ୍ ଲେଖନ୍ତୁ",
        line2: "ଦୟାକରି ଆପଣଙ୍କର କର୍ମଚାରୀ କୋଡ୍ ଲେଖନ୍ତୁ",
        line3: "କର୍ମଚାରୀ କୋଡ୍",
        line4: "ଦାଖଲ କରନ୍ତୁ",
        error: {
            error1: "କର୍ମଚାରୀ କୋଡ୍ ଖାଲି ନାହିଁ",
            error2:  "ଓହ୍! କିଛି ଭୁଲ୍ ହୋଇଗଲା। ଦୟାକରି ପ୍ରଶାସକଙ୍କ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ।",
            error3: "ଲଗଇନ୍ ତଥ୍ୟ ମିଳିଲାନି",
            error4: "ଆପଣ ସଂସ୍ଥାପିତ ନୁହେଁ। ପ୍ରଥମେ ଲଗଇନ୍ କରନ୍ତୁ",
            error5: "ଏହି କୋଡ୍ ସହିତ କର୍ମଚାରୀ ପ୍ରାପ୍ତ ହେଲାନି"
        }
    },
    salesforce: {
        line1: "ସେଲସ୍ ଫୋର୍ସ ସହିତ ଲଗଇନ୍ କରନ୍ତୁ",
        line2: "ସେଲସ୍ ଫୋର୍ସ ସହିତ ଲଗଇନ୍ କରନ୍ତୁ"
    },
    // qr: "ପ୍ରିୟ ଗ୍ରାହକ, ଆପଣଙ୍କର ପ୍ରତିକ୍ରିୟା ଆମ ପାଇଁ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ! ଆମେ ସେବାକୁ ବିକଶିତ କରିବା ପାଇଁ ଆପଣଙ୍କର କିମ୍ମତ ପ୍ରତିକ୍ରିୟା ଚାହାଁ"
    qr: "ପ୍ରିୟ ଗ୍ରାହକ,",
    qrQuote: "ଦୟାକରି ଶାଖାରେ ଆପଣଙ୍କର ବ୍ୟାଙ୍କିଙ୍ଗ ଅଭିଜ୍ଞତା ଉପରେ ମତାମତ ପ୍ରଦାନ କରନ୍ତୁ | ଯାହାଦ୍ୱାରା ଆମେ ଆପଣଙ୍କୁ ଉତ୍ତମ ସେବା ଯୋଗାଇ ପାରିବା|"
}

export default odiya;
