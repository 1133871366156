const bengali = {
    feedbackServiceFlow: {
        line1: "সাধারণ প্রতিক্রিয়া",
        line2: "সেবা স্তরের প্রতিক্রিয়া"
    },
    mobile: {
        line1: "আপনার মোবাইল নম্বর লিখুন",
        line2: "আমরা আপনাকে একটি যাচাইকরণ কোড পাঠাবো",
        line3: "মোবাইল নম্বর লিখুন",
        line4: "চালিয়ে যান",
        error: {
            error: "একটি ত্রুটি ঘটেছে। অনুগ্রহ করে পরে আবার চেষ্টা করুন",
            otp: "OTP পাঠাতে ব্যর্থ। অনুগ্রহ করে পরে আবার চেষ্টা করুন"
        }
    },
    otp: {
        line1: "যাচাইকরণ কোড",
        line2: "আমরা আপনার মোবাইল নম্বরে যাচাইকরণ কোড পাঠিয়েছি",
        line3: "OTP পাননি?",
        line4: "চালিয়ে যান",
        line5: "প্রতিক্রিয়া পরিষেবা ধরন নির্বাচন করুন",
        line6: "নগদ জমা",
        line7: "ফান্ড স্থানান্তর",
        line8: "অ্যাকাউন্ট খোলার",
        line9: "SB অ্যাকাউন্ট সম্পর্কিত পরিষেবা",
        line10: "বর্তমান অ্যাকাউন্ট",
        line11: "বাতিল করুন",
        line12: "পরবর্তী",
        line13: "OTP আবার পাঠান",
        line14: "OTP সফলভাবে পাঠানো হয়েছে",
        noTransactionTypes: "আপনি কোন পরিষেবা ব্যবহার না করে প্রতিক্রিয়া জমা দিতে পারবেন না",
        error: {
            error: "OTP পাঠানোর সময় ত্রুটি। অনুগ্রহ করে পরে আবার চেষ্টা করুন",
            otp: "অবৈধ OTP"
        }
    },
    feedback: {
        line1: "আজ আমাদের সাথে আপনার অভিজ্ঞতা মূল্যায়ন করুন",
        verypoor: "খুব খারাপ",
        belowAverage: "গড়ের নিচে",
        average: "গড়",
        good: "ভালো",
        veryGood: "খুব ভালো",
        excellent: "অত্যন্ত ভালো",        
        outstanding: "অসাধারণ",
        poorFeedbackHeader: "আমরা অসন্তুষ্ট, অনুগ্রহ করে আপনার অসন্তুষ্টির কারণ ব্যাখ্যা করুন:",
        badService: "খারাপ সেবা",
        notGoodSupport: "ভালো গ্রাহক সেবা নেই",
        others: "অন্যান্য",
        textareaPlaceholder: "আমরা আপনার অসুবিধার জন্য দুঃখিত",
        captchaPlaceholder: "ক্যাপচা লিখুন",
        reload: "ক্যাপচা রিলোড করুন",
        submit: "জমা দিন",
        work: "কাজ সম্পূর্ণ হয়নি",
        inadequate: "পণ্যের/সেবার উপর কর্মীদের যথেষ্ট জ্ঞান নেই",
        guidance: "কাজ করার জন্য সঠিক দিকনির্দেশনা দেয়া হয়নি",
        staff: "কর্মীদের আচরণ",
        products: "পণ্য/প্রক্রিয়া গ্রাহকের চাহিদা মেটাতে সক্ষম নয়",
        other: "অন্যান্য কিছু",
        general1: "কাজ সন্তোষজনকভাবে শেষ হয়নি",
        general2: "পণ্য/সেবার উপর কর্মীদের যথেষ্ট ধারণা নেই",
        general3: "কাজ করার জন্য সঠিক দিকনির্দেশনা দেয়া হয়নি",
        general4: "কর্মীদের আচরণ",
        general5: "পণ্য/প্রক্রিয়া গ্রাহকের চাহিদা মেটাতে সক্ষম নয়",
        general6: "অন্যান্য",
        error: {
            selectRating: "অনুগ্রহ করে রেটিং নির্বাচন করুন",
            incorrectCaptcha: "ক্যাপচা ভুল",
            submitFeedback: "যাচাই ছাড়া আপনি প্রতিক্রিয়া দিতে পারবেন না",
            twice: "আপনি দ্বিতীয় বার প্রতিক্রিয়া দিতে পারবেন না",
            transaction: "আপনি কোন লেনদেন করার আগে প্রতিক্রিয়া দিতে পারবেন না"
        }
    },
    thankYou: {
        line1: "প্রতিক্রিয়া দেওয়ার জন্য ধন্যবাদ,",
        line2: "আমরা আপনার প্রতিক্রিয়া মূল্যায়ন করি"
    },
    feedbackMessage: {
        line1: "আমরা ইতিমধ্যেই আপনার প্রতিক্রিয়া গ্রহণ করেছি",
        line2: "আপনি দ্বিতীয় বার জমা দিতে পারবেন না"
    },
    employee: {
        line1: "কর্মচারী কোড লিখুন",
        line2: "অনুগ্রহ করে আপনার কর্মচারী কোড লিখুন",
        line3: "কর্মচারী কোড",
        line4: "জমা দিন",
        error: {
            error1: "কর্মচারী কোড খালি থাকতে পারে না",
            error2: "ওহ! কিছু ভুল হয়ে গেছে। দয়া করে প্রশাসকের সাথে যোগাযোগ করুন।",
            error3: "লগইন তথ্য পাওয়া যায়নি",
            error4: "আপনি যাচাই করা হয়নি। দয়া করে প্রথমে লগইন করুন",
            error5: "এই আইডি দিয়ে কর্মচারী খুঁজে পাওয়া যায়নি"
        }
    },
    salesforce: {
        line1: "সেলসফোর্সের মাধ্যমে লগইন করুন",
        line2: "সেলসফোর্সের মাধ্যমে লগইন করুন"
    },
    // qr: "প্রিয় গ্রাহক, আপনার প্রতিক্রিয়া আমাদের জন্য গুরুত্বপূর্ণ! আমাদের সেবা উন্নত করতে আপনার মূল্যবান মতামত দান করুন"
qr: "প্রিয় গ্রাহক,",
qrQuote: "অনুগ্রহ করে শাখায় আপনার ব্যাঙ্কিং অভিজ্ঞতা সম্পর্কে মতামত দিন। যাতে আমরা আপনাকে আরও ভাল পরিষেবা দিতে পারি।"
}

export default bengali;
