import React,{useState, useEffect} from 'react';
import './Form.css';
import QRCode from 'react-qr-code';
import { encryptData } from '../utils/cryptoUtils';

const FeedbackFormFront = () => {
    const [empDetails, setEmpDetails] = useState({
        empCode: '',
        empName: '',
        empDpCode: '',
        empBranchName : ''
      });
      const [qrURL,setQRUrl] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const empCode = params.get('emp_code') || localStorage.getItem('emp_code') || localStorage.getItem('employeeCode');
        const empName = params.get('emp_name') || localStorage.getItem('employeeName');
        const empDpCode = params.get('dp_code') || localStorage.getItem('dp_code') || localStorage.getItem('employeeDpCode');
        const empBranchName = params.get('branch_name') || localStorage.getItem('employeeBranchName');
        setEmpDetails({ empCode, empName, empDpCode,empBranchName });
        const queryString = `${empCode}|${empDpCode}`;
        console.log("queryString",queryString);
        const encryptedQuery = encryptData(queryString); 
        console.log("encryptedQuery",encryptedQuery);
        const finalURL = `${process.env.REACT_APP_WEB_BASE_URL}phone-number?data=${encodeURIComponent(encryptedQuery)}`;
        console.log("finalURL",finalURL); 
        setQRUrl(finalURL);
      }, []);
    return(
    <div class="main_container1">

    <div class="container1">
       <div class="header1">
          <img src="formimages/canara_icon.png" class="logo_icon" />
          <span>
          <img src="formimages/canara_logo.png" class="logo" />
          <h4>Together We can</h4>
          </span>
          
       </div>
       
       <div class="content_style">
       <div class="content_box">
          <div class="scan">
          {/* <img src="formimages/scan_back.png" /> */}
          <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`${qrURL}`}
                  />
          <h4>Please Scan Here</h4>
          
          </div>
          <h2>Your <strong>Feedback</strong> Matters </h2>	 
          </div>
          <div class="content_box">
          <span>
          <h4><img src="formimages/comma_left.png" /> प्रिय ग्राहक,<br/>
          कृपया शाखा बैंकिंग अनुभव पर अपनी प्रतिक्रिया प्रस्तुत करें और सर्वोत्तम अभ्यास प्रदान करने में हमारी सहायता करें। </h4>	 
  </span>
          </div>
          <div class="content_box">
          <img src="formimages/customer.png" class="cust_img" />
          <h2 class="cust_txt"><strong>Dear customer,</strong><br></br>
  Kindly submit your feedback on branch banking experience and help us to provide the best practice. <img src="formimages/comma_right.png" /></h2>	 
          </div>
          <div class="content_box">
          <h4 class="cust_txt"><strong>Name:- </strong></h4>	 
          </div>
       </div>
       
       <div class="footer1">
          <ul>
          <li>		
          <img src="formimages/whatsapp.png" /> <strong>9076030001</strong>
          </li>
          </ul>
          <span>|</span>
          <ul>
          <li>		
          <img src="formimages/facebook.png" />
          </li>
          <li>		
          <img src="formimages/linkedin.png" />
          </li>
          <li>		
          <img src="formimages/pinterest.png" />
          </li>
          <li>		
          <img src="formimages/trend.png" />
          </li>
          <li>		
          <img src="formimages/youtube.png" />
          </li>
          <li>		
          <img src="formimages/X_icon.png" />
          </li>
          <li>		
          <img src="formimages/insta.png" />
          </li>
          <li>		
          <img src="formimages/user.png" />
          </li>
          </ul>
          <span>|</span>
           <ul>
          <li>		
          <img src="formimages/1stbank.png" class="bank_styl" /> <strong>1800 1030 </strong> 
          </li>
          </ul>
          <ul>
          <li>		
           <img src="formimages/lang.png" class="lang_styl" /> 
          </li>
          </ul>
          <span>|</span>
           <ul>
          <li> www.canarabank.com
          </li>
          </ul>
       
       </div>
    </div>
    <div class="container1">
       <div class="header1">
          <img src="formimages/canara_icon.png" class="logo_icon" />
          <span>
          <img src="formimages/canara_logo.png" class="logo" />
          <h4>Together We can</h4>
          </span>
          
       </div>
       
       <div class="content_style1">
          <img src="formimages/banner.png" /> 
          </div>
          </div>
    </div>
    )
}
  
export default FeedbackFormFront;
